import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { styled } from '@mui/material/styles';
import {
  Typography,
} from '@mui/material';
import Layout from '../../layouts/Default';
import GuidesList from '../../components/GuidesList/GuidesList';
import Section from '../../components/Section';
import GuidesImage from '../../../static/img/course.svg';

const HeaderImgContainer = styled('div')(() => ({
  gridColumn: 'span 6 / -1',
  textAlign: 'center',
}));

const HeaderGuidesImage = styled('div')(() => ({
  maxWidth: 500,
  margin: '0px auto',
}));

const HeaderText = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    marginTop: '48px',
  },
  gridArea: '1 / 1 / auto / span 5',
}));

const SectionText = styled('div')(({ theme }) => ({
  textAlign: 'center',
  margin: '0px auto',
  marginBottom: theme.spacing(8),
  maxWidth: 700,
}));

export default function GuidesIndexPage({
  data: {
    site: {
      siteMetadata: { title },
    },
  },
}) {
  return (
    <Layout>
      <Helmet title={`Guides | ${title}`} />
      <Section
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'block',
            padding: '64px 24px',
          },
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gap: '32px',
          alignItems: 'center',
          padding: '88px 32px',
        })}
      >
        <HeaderImgContainer>
          <HeaderGuidesImage>
            <img src={GuidesImage} alt="Guides" />
          </HeaderGuidesImage>
        </HeaderImgContainer>
        <HeaderText>
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                fontSize: '64px',
                lineHeight: '1',
              },
              fontWeight: 'bold',
              fontSize: '42px',
              lineHeight: '1.1',
            })}
            variant="h1"
            component="h1"
          >
            Guides
          </Typography>
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                fontSize: '24px',
                lineHeight: '1.3',
              },
              fontWeight: 'normal',
              fontSize: '22px',
              lineHeight: '1.5',
              marginBottom: '1.5rem',
              marginTop: '1.5rem',
            })}
            variant="h4"
            component="h4"
          >
            A collection of guides to help you make the most of Nualang.
          </Typography>
        </HeaderText>
      </Section>
      <Section
        sx={(theme) => ({
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(10),
        })}
      >
        <SectionText>
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                fontSize: '36px',
                lineHeight: '1',
              },
              fontWeight: 'bold',
              fontSize: '32px',
              lineHeight: '1.2',
            })}
            variant="h1"
            component="h1"
          >
            Learning with Nualang
          </Typography>
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                fontSize: '22px',
                lineHeight: '1.3',
              },
              fontWeight: 'normal',
              fontSize: '22px',
              lineHeight: '1.5',
              marginBottom: theme.spacing(3),
              marginTop: theme.spacing(3),
            })}
            variant="h4"
            component="h4"
          >
            Take your language learning to the next level.
          </Typography>
        </SectionText>
        <GuidesList tag="learn" />
      </Section>
      <Section
        sx={(theme) => ({
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(10),
        })}
      >
        <SectionText>
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                fontSize: '36px',
                lineHeight: '1',
              },
              fontWeight: 'bold',
              fontSize: '32px',
              lineHeight: '1.2',
            })}
            variant="h1"
            component="h1"
          >
            Teaching with Nualang
          </Typography>
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                fontSize: '22px',
                lineHeight: '1.3',
              },
              fontWeight: 'normal',
              fontSize: '22px',
              lineHeight: '1.5',
              marginBottom: theme.spacing(3),
              marginTop: theme.spacing(3),
            })}
            variant="h4"
            component="h4"
          >
            Learn how to use Nualang to supplement existing language teaching methods and lessons.
          </Typography>
        </SectionText>
        <GuidesList tag="teach" />
      </Section>
    </Layout>
  );
}

GuidesIndexPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
};

export const guidesPageQuery = graphql`
  query GuidesQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
