import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link, graphql, StaticQuery } from 'gatsby';
import { styled } from '@mui/material/styles';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import { capitalize } from '../../utils/index';

const Grid = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    gap: '30px 24px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gap: '24px',
  },
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: '50px 24px',
}));

const GridItem = styled(Link)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
  },
  display: 'block',
  cursor: 'pointer',
  color: 'inherit',
}));

const GridItemImage = styled('header')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '5px',
  transition: 'opacity 0.2s ease 0s',
  '&:hover': {
    opacity: 0.8,
  },
}));

export function GuidesPost({ post }) {
  return (
    <GridItem
      to={post.fields.slug}
      key={`guide-post-${post.id}`}
    >
      <article>
          {post.frontmatter.featuredimage ? (
            <GridItemImage>
              <PreviewCompatibleImage
                imageInfo={{
                  image: post.frontmatter.featuredimage,
                  alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                }}
              />
            </GridItemImage>
          ) : null}
          <Box fontSize={14} mt={2} mb={1}>
            {post.frontmatter.tags
              && post.frontmatter.tags.map((s, index) => `${capitalize(s)} ${post.frontmatter.tags[index + 1] ? ' | ' : ''}`)
            }
          </Box>
          <Box fontWeight="fontWeightBold" fontSize={22} mb={1}>
            {post.frontmatter.title}
          </Box>
          <Box fontWeight={500} fontSize={18}>
            {post.frontmatter.description}
          </Box>
      </article>
    </GridItem>
  );
}

GuidesPost.propTypes = {
  post: PropTypes.object,
};

export function Guides({ data, tag }) {
  const { edges: posts } = data.allMdx;
  return (
    <Grid>
      {posts
        && posts.map(({ node: post }) => {
          if (tag && post.frontmatter.tags && !post.frontmatter.tags.includes(tag)) {
            return null;
          }
          return (
            <GuidesPost key={`guide-post-${post.id}`} post={post} />
          );
        })}
        {posts && posts.length === 0
        && <Typography
          variant="h5"
          component="h5"
        >
          Posts coming soon :)
        </Typography>
       }
    </Grid>
  );
}

Guides.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  tag: PropTypes.string,
};

export default (props) => (
  <StaticQuery
    query={graphql`query GuidesListQuery {
  allMdx(
    sort: {order: ASC, fields: [frontmatter___date]}
    filter: {frontmatter: {templateKey: {eq: "guides-post"}, draft: {eq: false}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          templateKey
          date(formatString: "MMMM DD, YYYY")
          author {
            id
            name
            bio
            twitter
          }
          tags
          featuredpost
          featuredimage {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`}
    render={(data, count) => <Guides data={data} count={count} {...props} />}
  />
);
